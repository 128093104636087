import BaseService from "@/services/BaseService";
import { GatewayType } from "@/services/ApiClient";

class PropertyFolderService extends BaseService {
  constructor() {
    super(GatewayType.PORTAL);
  }

  async updatePropertyFolder(id, status) {
    this.apiClient.post("/WidgetTheme/ChangeFolderState", {
      dbcMlsListingId: id,
      status: status,
    });
  }
}

export default new PropertyFolderService();
